import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import CookieConsent from "react-cookie-consent"
import "../styles/global.scss"
import UIkit from 'uikit' //important for uk-classes, otherwise they won't work properly

import Seo from "../components/seo/seo.js"
import Nav from "../components/nav/nav.js"
import IceFooter from "../components/ice-footer/ice-footer.js"
import GoogleTagManager from "../components/seo/GoogleTagManager.js";



const Layout = ({ children, pageData, SEOTitle, SEODescription, footerDisclaimer, darkThemeNav, meta }) => {

    if (typeof window === 'undefined') {
        return null; // Don't render anything during server-side rendering
      }

    const [showPopup, setShowPopup] = useState(false);
    const [toggle1Checked, setToggle1Checked] = useState(true);
    const [toggle2Checked, setToggle2Checked] = useState(false);

    const updateToggle1Selection = (value) => {
        setToggle1Checked(value);
        const expiryDate = new Date();
        expiryDate.setDate(expiryDate.getDate() + 30);
        document.cookie = `ICEperformanceCookies=${value}; expires=${expiryDate.toUTCString()}; path=/`;
    };

    const updateToggle2Selection = (value) => {
        setToggle2Checked(value);
        const expiryDate = new Date();
        expiryDate.setDate(expiryDate.getDate() + 30);
        document.cookie = `ICEadvertisingCookies=${value}; expires=${expiryDate.toUTCString()}; path=/`;
    };

    useEffect(() => {
        const timeout = setTimeout(() => {

            const ICEperformanceCookies = document.cookie.split(';').find(cookie => cookie.includes('ICEperformanceCookies'));
            const ICEadvertisingCookies = document.cookie.split(';').find(cookie => cookie.includes('ICEadvertisingCookies'));
            const ICEMainCookies = document.cookie.split(';').find(cookie => cookie.includes('ICEMainCookies'));

            const shouldShowPopup = !ICEMainCookies;
            setShowPopup(shouldShowPopup);

            if (ICEperformanceCookies) {
                const value = ICEperformanceCookies.split('=')[1].trim();
                setToggle1Checked(value === 'true');
            }

            if (ICEadvertisingCookies) {
                const value = ICEadvertisingCookies.split('=')[1].trim();
                setToggle2Checked(value === 'true');
            }

        }, 3000);

        return () => clearTimeout(timeout);
    }, []);

    const handleCloseSave = () => {
        setShowPopup(false);
        updateToggle1Selection(toggle1Checked);
        updateToggle2Selection(toggle2Checked);
        handleGtagSave(toggle1Checked, toggle2Checked);
    };

    const handleToggle1Change = () => {
        const newValue = !toggle1Checked;
        setToggle1Checked(newValue);
        updateToggle1Selection(newValue);
    };

    const handleToggle2Change = () => {
        const newValue = !toggle2Checked;
        setToggle2Checked(newValue);
        updateToggle2Selection(newValue);
    };



    const handleGtagSave = (firstToggle, secondToggle) => {
        // if (typeof window !== 'undefined' && typeof window.gtag !== 'undefined') {
        //     window.gtag('event', `performance-cookies-${firstToggle}-advertising-cookies-${secondToggle}`);
        // }
        const expiryDate = new Date();
        expiryDate.setDate(expiryDate.getDate() + 30);
        document.cookie = `ICEMainCookies=${true}; expires=${expiryDate.toUTCString()}; path=/`;
    }


    const hasCookie = (document && document.cookie && document.cookie.split(';').find(cookie => cookie.includes('ICEMainCookies')))?true:false; 

    return (
        <>
            <Seo title={SEOTitle} description={SEODescription} meta={meta}></Seo>
            {(hasCookie)?<GoogleTagManager gtmId={'GTM-KRQDFJK'}/>:''}
            <Nav forcedDarkTheme={darkThemeNav}></Nav>

            {showPopup && (
                <div className="cookie-popup" uk-scrollspy="cls: uk-animation-scale-up; target: .popup;">
                    <div className="popup">
                        <div className="popup-content">

                            <div style={{ padding: "16px 0" }}>
                                <div>
                                    <span>Cookie Preferences</span>
                                    <p>At ICE Comparison we're continually trying to enhance your user experience and to do that we use cookies on our website. Click below to consent to the use of this technology on our site. For further details or to change your consent choices at any time - see our <a href="/privacy-and-cookie-policy">Cookie Policy</a>.
                                        Proceeding with these settings will keep the cookies activated. You can edit these below.
                                    </p>
                                    <button className="proceed-btn" onClick={handleCloseSave}></button>
                                    <b></b>
                                </div>

                                <div>
                                    <span>Necessary cookies</span>
                                    <p>Necessary cookies enable core functionality such as security, network management and accessibility. You may disable these by changing your browser settings, but this may affect how the website functions.</p>
                                    <b></b>
                                </div>

                                <div>
                                    <div className="toggle-content">
                                        <span>Performance cookies</span>
                                        <Toggle
                                            defaultChecked={toggle1Checked}
                                            onChange={handleToggle1Change}
                                        />
                                    </div>
                                    <p> We'd like to set performance cookies to help us to improve our website by collecting and reporting information on how to use it. The cookies collect information in a way that does not directly identify anyone. For more information on how these cookies work, please see our <a href="/privacy-and-cookie-policy">Cookie Policy</a>.</p>
                                    <b></b>
                                </div>

                                <div>
                                    <div className="toggle-content">
                                        <span>Targeting/‎Advertising</span>
                                        <Toggle
                                            defaultChecked={toggle2Checked}
                                            onChange={handleToggle2Change}
                                        />
                                    </div>
                                    <p>We use cookies from our third-party partners on our site to serve you with advertisements that we believe are relevant to you and your interests. You may see these advertisements on our site and on other sites that you visit.</p>
                                    <b></b>
                                </div>

                                <div><button onClick={handleCloseSave} className="close-btn">SAVE AND CLOSE</button></div>
                            </div>

                        </div>
                    </div>
                </div>
            )}

            <main>{children}</main>
            <IceFooter disclaimer={footerDisclaimer}></IceFooter>
            
            {/* <CookieConsent
                location="bottom"
                cookieName="iceCookie"
                style={{ background: "#cce6e9", color: "#232826" }}
                buttonStyle={{ background: "#333", color: "#fff" }}
                expires={150}>
                We are committed to preserving the privacy of all users of this website.
                <Link to="/privacy-and-cookie-policy" className="privacy-more" style={{ color: "rgba(0, 0, 0, 0.6)" }}> Find out more</Link>
            </CookieConsent>  */}
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
